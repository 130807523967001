import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
const fadeAnimation = keyframes`${fadeInUp}`;

const Services = ({ offset }) => {
  const [data] = useState([
    {
      id: 1,
      title: "Developpement de site web",
      desc: "Site vitrine, E-commerce, application mobile, hebergement, nom de domaine.",
    },
    {
      id: 2,
      title: "Intégration Web",
      desc: "Vous avez une maquette ou un template de site Web ? Je l'integre pour votre site internet en respectant les standarts HTML / CSS.",
    },
    {
      id: 3,
      title: "Conception Graphique",
      desc: "Création de logo et de templates Web-design.",
    },
    {
      id: 4,
      title: "Le référencement naturel",
      desc: "Le moteur de recherche Google est notre ami, nous l'utilisons pour votre référencement naturel SEO.",
    },
    {
      id: 5,
      title: "Des sites responsive design",
      desc: "Tous nos sites web s'adaptent sur les ordinateurs bureau ou portable, tablettes et mobiles.",
    },
    {
      id: 6,
      title: "Une interface d'administration",
      desc: "Nous pouvons vous mettre à disposition une interface administrateur pour la gestion de contenu.",
    },
  ]);
  return (
    <ServicesWrapper id="services">
      <h2>Mes Services</h2>
      <hr className="hr-prop"></hr>
      <DevServices>
        <div className={`${offset > 2100 ? "visible" : "hidden"}`}>
          {data.map((service) => {
            return (
              <Card key={service.id}>
                <h4>{service.title}</h4>
                <p>{service.desc}</p>
              </Card>
            );
          })}
        </div>
      </DevServices>
    </ServicesWrapper>
  );
};

const ServicesWrapper = styled.div`
  width: 100%;
  min-height: 70vh;
  height: auto;
  background-color: var(--background);
  color: #e2e8f0;
  padding: 0 0 10rem 0;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  justify-content: center;
  h2 {
    padding: 6rem 0 0 0;
    text-align: center;
    font-family: "Roboto Slab", serif;
    font-size: 2.5rem;
  }

  .hr-prop {
    width: 10%;
    margin: 0.5rem auto;
    background: #e2e8f0;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 479px) {
    h2 {
      padding: 9rem 0 0 0;
    }
    clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
  }
`;

const DevServices = styled.div`
  .visible {
    animation: 4s ${fadeAnimation};
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    margin: 0rem auto;
    justify-content: space-around;
  }

  .hidden {
    display: none;
  }
`;

const Card = styled.div`
  width: 18rem;
  margin: 1.5rem;
  min-height: 200px;
  border: 2px #00bfff solid;
  border-radius: 10px;
  font-family: "Lato", serif;
  padding: 1rem;

  h4 {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
  }

  p {
    text-align: center;
    font-size: 1rem;
    margin: 1rem;
  }
`;
export default Services;
