import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
const fadeAnimation = keyframes`${fadeInUp}`;

const Advantage = ({ offset }) => {
  return (
    <AdvantagesWrapper>
      <div className={`${offset > 3350 ? "visible" : "hidden"}`}>
        <h2>Le choix de notre technologie</h2>
        <p>
          Pendant longtemps, utiliser un CMS couplé était la norme: il
          organisait les efforts de développement et donnait aux clients
          l'autonomie pour gérer leur site.
        </p>
        <p>
          Dans le developpement moderne, nous avons pris conscience que les CMS
          traditionnels (WordPress, Drupal, Prestashop ) essayaient juste de
          faire… trop.
        </p>
        <p>
          Grâce aux navigateurs modernes, aux générateurs de sites statiques,
          aux CDN et aux API, nous voyons maintenant les développeurs Web passer
          des applications dynamiques côté serveur à des piles modulaires côté
          client et chez MVDev nous faisons la même chose.
        </p>
      </div>
      <div className={`${offset > 3390 ? "visible" : "hidden"}`}>
        <h2>Les advantages de nos services </h2>
        <ul>
          <li>
            <p>
              La délégation des opérations côté serveur et base de données
              supprime de nombreux points de pannes et d'exploits de sécurité.
            </p>
          </li>
          <li>
            <p>
              Le contenu statique servi via CDN permet une expérience
              utilisateur plus rapide.
            </p>
          </li>
          <p>La moindre complexité du développement réduit les coûts.</p>
          <li></li>
          <li>
            <p>
              La réduction de l'entretien en + une flexibilité dans les flux de
              travail.
            </p>
          </li>
        </ul>
      </div>
      <div className={`${offset > 3900 ? "visible" : "hidden"}`}>
        <h2>
          Coût de developpement reduit ainsi que les frais d'hebergement
          supprimé
        </h2>
      </div>
    </AdvantagesWrapper>
  );
};

const AdvantagesWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #141821;
  color: #e2e8f0;
  margin: 5rem 0 0 0;
  padding-top: 3rem 2rem;
  background: linear-gradient(to right, slateblue 0%, deepskyblue 100%);
  text-align: justify;
  .visible {
    animation: 4s ${fadeAnimation};
    h2 {
      text-align: center;
      padding: 2rem;
      font-family: "Roboto Slab", serif;
    }

    p {
      /* margin: 3rem 3rem 0 3rem; */
      font-size: 1.1rem;
      padding: 0.3rem 5rem;
    }

    ul {
      padding: 2rem 0;
    }

    @media screen and (max-width: 765px) {
      p {
        font-size: 0.8rem;
        padding: 0.3rem 1rem;
      }
    }
  }
  .hidden {
    display: none;
  }
`;
export default Advantage;
