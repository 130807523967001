import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { fadeIn } from "react-animations";
const fadeAnimation = keyframes`${fadeIn}`;

const Home = () => {
  return (
    <StartWrapper>
      <div className="textwrapper">
        <h1>
          <strong>Développeur Web Freelance</strong>
        </h1>
        <hr className="hr-prop"></hr>
        <h2>
          <strong>
            Site Web{" | "} Application{" | "} Design
          </strong>
        </h2>
      </div>
      <FontAwesomeIcon icon={faArrowCircleDown} className="arrow" />
    </StartWrapper>
  );
};

const StartWrapper = styled.div`
  position: relative;
  width: 100wh;
  height: auto;
  min-height: 90vh;
  background: #141821;
  color: #e2e8f0;
  display: flex;
  animation: 10s ${fadeAnimation};

  .textwrapper {
    width: 80%;
    height: auto;
    position: absolute;
    top: 40%;
    left: 40%; /* à 50%/50% du parent référent */
    transform: translate(-40%, -50%); /* décalage de 50% de sa propre taille */

    h1 {
      font-size: 3.5rem;
      font-family: "Lato", sans-serif;
      line-height: 6rem;
      font-weight: bold;
    }
    h2 {
      text-align: center;
      font-family: "Roboto Slab", serif;
      font-weight: bold;
      line-height: 3rem;
    }
    .hr-prop {
      width: 30%;
      border-top: 2px solid #e2e8f0;
      margin: 0.5rem auto;
      margin-bottom: 2rem;
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2rem;
    animation: bounce 2.7s ease-in-out infinite;
  }

  @media screen and (max-width: 479px) {
    h1 {
      margin-top: 5rem;
      font-size: 2.5rem !important;
    }
    h2 {
      font-size: 1rem;
      margin-top: -2rem;
    }
  }

  @keyframes bounce {
    from {
      top: 78%;
      color: deepskyblue;
    }
    50% {
      top: 80%;
      color: #6a5acd;
    }
    to {
      top: 78%;
      color: deepskyblue;
    }
  }
`;

export default Home;
