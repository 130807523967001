import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMugHot,
  faEdit,
  faDraftingCompass,
  faCode,
  faCheck,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
const fadeAnimation = keyframes`${fadeInUp}`;

const Etapes = ({ offset }) => {
  const [etapes] = useState([
    { id: 1, title: "1. Premier rendez-vous", icon: faMugHot },
    { id: 2, title: "2. Analyse du projet", icon: faEdit },
    { id: 3, title: "3. Création du design", icon: faDraftingCompass },
    { id: 4, title: "4. Transformation du desgin en code", icon: faCode },
    { id: 5, title: "5. Validation et tests", icon: faCheck },
    { id: 6, title: "6. Livraison du projet", icon: faSitemap },
  ]);
  return (
    <EtapesWrapper>
      <h4>Votre projet étape par étape</h4>
      <hr className="hr-prop"></hr>
      <Wrapper>
        <div className={`${offset > 1330 ? "visible" : "hidden"}`}>
          {etapes.map((etape) => {
            return (
              <Card key={etape.id}>
                <FontAwesomeIcon icon={etape.icon} className="icon" />
                <h6>{etape.title}</h6>
              </Card>
            );
          })}
        </div>
      </Wrapper>
    </EtapesWrapper>
  );
};

const EtapesWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #e2e8f0;
  color: #141821;
  margin: -3rem 0;
  font-family: "Lato", sans-serif;

  h4 {
    padding: 5rem 0 0 0;
    margin: 3rem 0 0 0;
    text-align: center;
    font-family: "Roboto Slab", serif;
    font-size: 2.5rem;
  }
  .hr-prop {
    width: 20%;
    margin: 0.5rem auto;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 479px) {
    margin: -1rem 0 10rem 0;
  }
`;
const Wrapper = styled.div`
  .visible {
    animation: 3s ${fadeAnimation};
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 3rem auto;
    justify-content: space-evenly;
    .hidden {
      animation: 5s ${fadeAnimation};
      display: none;
    }
  }
`;
const Card = styled.div`
  width: 15rem;
  height: auto;
  text-align: center;
  font-size: 2rem;
  margin: 2rem 2rem;

  h6 {
    font-size: 1rem;
    margin: 2rem 0;
  }
`;
export default Etapes;
