import React, { useEffect, useState } from "react";
import Advantage from "../components/Advantage";
import Etapes from "../components/Etapes";
import Home from "../components/Home";
import Layout from "../components/layout";
import Parallax from "../components/Parallax";
import Plus from "../components/Plus";
import Services from "../components/Services";
import Slogan from "../components/Slogan";
import SEO from "../components/seo";
// markup
const IndexPage = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Layout>
      <SEO title="MVDev Développeur Web Freelance le Pradet, Création de site vitrine E-commerce, application | Accueil" />
      <Home offset={offset} />
      <Slogan offset={offset} />
      <Parallax />
      <Etapes offset={offset} />
      <Services offset={offset} />
      <Advantage offset={offset} />
      <Plus />
    </Layout>
  );
};

export default IndexPage;
