import React from "react";
import Background from "../images/pexels-mateusz-dach-450035.jpg";
import styled from "styled-components";

const Plus = () => {
  return (
    <PlusWrapper>
      <PlusWrapperCont1>
        <BackgroundWrapper />
        <h1>Notre but est de créer un site à votre image et de qualité.</h1>
      </PlusWrapperCont1>
      <PlusWrapperCont2>
        <h1>Qu'est ce que nous avons de plus a vous offrir ?</h1>
        <p>Interlocuteur unique, je gère votre projet de A à Z.</p>
        <p>
          Webdesign et intégration. Développement Headless cms sur mesure, Seo
          et positionnement Google. Sécurisation/Maintenance, forfaits de
          support.
        </p>
      </PlusWrapperCont2>
    </PlusWrapper>
  );
};

const PlusWrapper = styled.div`
  width: 100wh;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(379px, 1fr));
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 650px;
  background: url(${Background});
  background-size: cover;
  filter: brightness(0.5);
  z-index: 1;
`;

const PlusWrapperCont1 = styled.div`
  height: 600px;
  h1 {
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 0 2rem;
    position: relative;
    top: -20rem;
    left: 0;
    z-index: 100;
  }
`;
const PlusWrapperCont2 = styled.div`
  background: #141821;
  padding: 9rem 2rem;
  height: 650px;

  h1 {
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 0 2rem;
  }
  p {
    color: #fff;
    padding: 0 2rem;
    margin: 2rem 0;
    text-align: center;
  }
`;
export default Plus;
