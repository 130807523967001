import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
const fadeAnimation = keyframes`${fadeInUp}`;

const Slogan = ({ offset }) => {
  return (
    <SloganWrapper as="section">
      <TextWrapper>
        <div className={`${offset > 130 ? "visible" : "hidden"}`}>
          <h4>
            <strong>
              NOUS UTILISONS LES TOUTES DERNIERES TECHNOLOGIES ET FRAMEWORK
            </strong>
          </h4>
          <hr className="hr-prop"></hr>
          <h3>
            MVDev agence web qui est totalement à votre écoute tout au long de
            votre projet web.
          </h3>
          <h5>
            <strong>
              Notre volonté est de vous accompagner et de vous conseiller pour
              concevoir la création de site Web, refonte de site, de E-commerce
              ou une application mobile.
            </strong>
          </h5>
        </div>
      </TextWrapper>
    </SloganWrapper>
  );
};

const SloganWrapper = styled.div`
  width: 100%;
  min-height: 30vh;
  height: auto;
  text-align: center;
  background-color: var(--textWhite);
  display: flex;
  flex-direction: column;
  vertical-align: center;
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  padding: 5rem 0;

  @media screen and (max-width: 479px) {
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
  }
`;
const TextWrapper = styled.div`
  width: 80%;
  justify-content: center;
  margin: 4rem 0;
  min-height: 350px;
  .hidden {
    animation: 5s ${fadeAnimation};
    display: none;
  }

  .visible {
    animation: 1s ${fadeAnimation};
    h3 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    h4 {
      font-family: "Roboto Slab", serif;
    }

    h5 {
      font-size: 1.4rem;
      margin: 4rem 0;
      line-height: 2.5rem;
    }

    .hr-prop {
      width: 20%;
      margin: 0.5rem auto;
      margin-bottom: 2rem;
    }
    @media screen and (max-width: 479px) {
      margin: 3rem 0;
    }
  }
`;
export default Slogan;
